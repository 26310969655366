import Vue from 'vue'
import { isToday } from './utils'

export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

export const upperCase = value => (String(value)
  .toUpperCase())

export const title = (value, replacer = ' ') => {
  if (!value) return ''
  const str = value.toString()

  const arr = str.split(replacer)
  const capitalizedArray = []
  arr.forEach(word => {
    const capitalized = word.charAt(0)
      .toUpperCase() + word.slice(1)
    capitalizedArray.push(capitalized)
  })
  return capitalizedArray.join(' ')
}

export const avatarText = value => {
  if (!value) return ''
  const nameArray = value.split(' ')
  return nameArray.map(word => word.charAt(0)
    .toUpperCase())
    .join('')
}

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = {
  month: 'short',
  day: 'numeric',
  year: 'numeric'
}) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const formatTime = (value, fmt) => {
  if (!value) value = new Date().getTime() / 1000

  const date = new Date(value * 1000)
  const o = {
    'M+': date.getMonth() + 1,                //月份
    'd+': date.getDate(),                    //日
    'h+': date.getHours(),                  //小时
    'm+': date.getMinutes(),                //分
    's+': date.getSeconds(),                //秒
    'q+': Math.floor((date.getMonth() + 3) / 3), //季度
    'S': date.getMilliseconds()            //毫秒
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (const k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }
  return fmt
}

export const toTime = (value) => {
  if (value!=null){
    return formatTime(value, 'yyyy-MM-dd hh:mm')
  }
}
export const toDate = (value) => {
  return formatTime(value, 'yyyy-MM-dd')
}
export const toMonth = (value) => {
  return formatTime(value, 'yyyy-MM')
}
export const toMonthDate = (value) => {
  return formatTime(value, 'MM-dd')
}

export const getBatchNoForTime= (value,fmt) =>{
   if (!value) return ''
  return formatTime(value / 1000,fmt)
}

export const second = (value) => {
  if (!value) return ''
  return Date.parse(value) / 1000
}

export const compareTodayDate = (value) => {
  let now = second(value)
  let start = Date.parse(new Date(new Date().setHours(0, 0, 0, 0)))/1000
  let end = Date.parse(new Date(new Date().setHours(23, 59, 59, 59)))/1000
  if (now<end){
    return true
  }else {
    return false
  }
}

export const get_upload_path = (attachment) => {
  if (attachment) {
    if (attachment.path) {
      return attachment.path
    } else {
      return attachment
    }
  }
  return null
}
export const get_upload_url = (attachment) => {
  let path = get_upload_path(attachment)
  if (path != null && path != undefined) {
    if (path.indexOf('http://') != -1 || path.indexOf('https://') != -1) {
      return path
    }
  }
  return process.env.VUE_APP_OSS_URL + '/' + path
}

export const get_upload_preview = (attachment) => {
  let preview_url = ''
  if (attachment.icon == 'png'
      || attachment.icon == 'jpg'
      || attachment.icon == 'jpeg' ) {
    preview_url = get_upload_url(attachment)
  }
  else {
    preview_url = require('@/assets/images/icons/'+attachment.icon+'.png')
  }
  return preview_url
}

/**
 * Return short human friendly month representation of date
 * Can also convert date to only time if date is of today (Better UX)
 * @param {String} value date to format
 * @param {Boolean} toTimeForCurrentDay Shall convert to time if day is today/current
 */
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = {
    month: 'short',
    day: 'numeric'
  }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = {
      hour: 'numeric',
      minute: 'numeric'
    }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// Strip all the tags from markup and return plain text
export const filterTags = value => value.replace(/<\/?[^>]+(>|$)/g, '')

export const getCodeOptions = (type, isAll = false) => {
  let data = Vue.prototype.$code_table
  if (data[type]) {
    let array = data[type] || []
    if (array) {
      if (isAll) {
        return array
      } else {
        return array.filter(function (item, index) {
          return item.state === 1
        })
      }
    }
  }
  return []
}
export const getCode = (type, value) => {
  let array = getCodeOptions(type, true)
  return array.find(item => String(item.value) == String(value)) || ""
}
export const getCodeColor = (type, value) => {
  let code = getCode(type, value)
  return code['color'] || ''
}
export const getCodeLabel = (type, value, field) => {
  let code = getCode(type, value)
  return code[field || 'label'] || ''
}
export const getCodeIcon = (type, value) => {
  let code = getCode(type, value)
  return code['icon'] || ''
}
export const getCodeRemark = (type, value) => {
  let code = getCode(type, value)
  return code['remark'] || ''
}
export const kebabize = str => {
  return str.split('')
    .map((letter, idx) => {
      return letter.toUpperCase() === letter
        ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
        : letter
    })
    .join('')
}
export const isEmpty = (params) => {
  return params == null || params == undefined || params == ""
}

//去除0
export const isEmptys = (params) => {
  return params == null || params == undefined || params === ""
}


//yang data is number
export const isNumber = (number)=>{
  return  Number((number===null||number===undefined||number==="")?0:number)
}
//yang  two data is number and add
export const addNumber = (numberOne,numberTwo)=>{
  return  (Number(isNumber(numberOne))+Number(isNumber(numberTwo))).toFixed(2)
}

export const isInArr = (arr,value) => {
  let index = arr.indexOf(value);
  if(index>-1){
    return true;
  }
  return false ;
}

export const zeroNumber = (number)=>{
  if(number>9){
    return number;
  }else{
    return '0'+number;
  }
}

export const lodopWatermark = function (lodop) {
  if (process.env.VUE_APP_HOST == 'staging.sbc.xyb2b.com'){
    lodop. ADD_PRINT_SETUP_BKIMG("<img border='0' src='https://oss.sbc.xyb2b.com/sbc/product/6e3e7ae324c28616b46893c4e6a71489.png' style='z-index: -1'/>");
    lodop.SET_SHOW_MODE("BKIMG_IN_PREVIEW",1);
    lodop.SET_SHOW_MODE("BKIMG_PRINT",1);
    lodop.SET_SHOW_MODE("BKIMG_LEFT",10);
    lodop.SET_SHOW_MODE("BKIMG_TOP",10);
  }
}

//数组删除某
export const removeOneParamsFromArr = function (arr,value) {
    let index = arr.indexOf(value);
    if(index>-1){
      arr.splice(index,1);
    }
    return arr ;
}

//数组求差集
export const removeOneArrFromArr = function (arr,fromArr) {
  fromArr.forEach(item=>{
    let index = arr.indexOf(item);
    if(index>-1){
      arr.splice(index,1);
    }
  })
  return arr ;
}

// 数组去重
export const removeDuplicate = function (arr) {
  const newArr = []
  arr.forEach(item => {
    if (newArr.indexOf(item) === -1) {
      newArr.push(item)
    }
  })
  return newArr // 返回一个新数组
}



//处理换行
export const commendContent  = function (content) {
  let arr = content.split("");
  return arr.map((item) => {
    return item === "\n" ? "<br>" : item
  }).join("")
}
//所传时间 月初
export const nowTimeBeginForMonth = function (date) {
  const today = new Date()
  today.setTime(date * 1000)
  today.setDate(1);
  return today.getTime() / 1000
}

//0返回flase
export const isEmptyNew = (params) => {
  if(params == 0) return false
  return params == null || params == undefined || params == ""
}


//所传时间 月末
export const nowTimeEndForMonth = function (date) {
  const endDay = new Date(date*1000)
  endDay.setMonth(endDay.getMonth()+1)
  endDay.setDate(0);
  return endDay.getTime()/1000
}

//返回绝对值
export const getAbs = (params) => {
  return params >= 0 ? params : -params
}

